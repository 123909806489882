// const mockPermissions = [
//   {
//     action: 'Access - My Campaign',
//     subject: 'Campaigns',
//   },
//   {
//     action: 'Access - All Campaign',
//     subject: 'Campaigns',
//   },
//   {
//     action: 'Access',
//     subject: 'Campaigns > Products',
//   },
//   {
//     action: 'Edit',
//     subject: 'Campaigns > Products',
//   },
//   {
//     action: 'Access',
//     subject: 'Campaigns > HeroVisionX Filter',
//   },
//   {
//     action: 'Edit',
//     subject: 'Campaigns > HeroVisionX Filter',
//   },
//   {
//     action: 'Access',
//     subject: 'Campaigns > Approval History',
//   },
//   {
//     action: 'Upsell',
//     subject: 'Campaigns > Campaign',
//   },
//   {
//     action: 'Retain',
//     subject: 'Campaigns > Campaign',
//   },
//   {
//     action: 'Auto Sync',
//     subject: 'Campaigns > Campaign',
//   },
//   {
//     action: 'Delete Sync',
//     subject: 'Campaigns > Campaign',
//   },
//   {
//     action: 'Media History',
//     subject: 'Campaigns > Campaign',
//   },
//   {
//     action: 'Ack',
//     subject: 'Campaigns > Campaign',
//   },
//   {
//     action: 'Import',
//     subject: 'Campaigns > Campaign',
//   },
//   {
//     action: 'Export',
//     subject: 'Campaigns > Campaign',
//   },
//   {
//     action: 'Allocate',
//     subject: 'Campaigns > Campaign',
//   },
//   {
//     action: 'Delete',
//     subject: 'Campaigns > Campaign',
//   },
//   {
//     action: 'Edit',
//     subject: 'Campaigns > Campaign > Solution Plan',
//   },
//   {
//     action: 'Add Cycle',
//     subject: 'Campaigns > Campaign > Solution Plan',
//   },
//   {
//     action: 'Edit',
//     subject: 'Campaigns > Campaign > Campaign Overview',
//   },
//   {
//     action: 'Edit',
//     subject: 'Campaigns > Staff Assignment > Optimizer',
//   },
//   {
//     action: 'Access',
//     subject: 'Accounts',
//   },
//   {
//     action: 'Access',
//     subject: 'Opportunities',
//   },
//   {
//     action: 'Access',
//     subject: 'Opportunities > Opportunity Product',
//   },
//   {
//     action: 'Access',
//     subject: 'Opportunities > Payment Calendar',
//   },
//   {
//     action: 'Create Payment Cycle',
//     subject: 'Opportunities > Payment Calendar',
//   },
//   {
//     action: 'Renew',
//     subject: 'Opportunities > Payment Calendar',
//   },
//   {
//     action: 'Create Campaign',
//     subject: 'Opportunities > Campaign',
//   },
//   {
//     action: 'Access',
//     subject: 'Wallets',
//   },
//   {
//     action: 'Transfer',
//     subject: 'Wallets',
//   },
//   {
//     action: 'Access',
//     subject: 'Channel Performance',
//   },
//   {
//     action: 'Access',
//     subject: 'ICONIC Campaign Monitor',
//   },
//   {
//     action: 'Access',
//     subject: 'HeroVisionX',
//   },
//   {
//     action: 'Edit Status',
//     subject: 'HeroVisionX > Lead Management',
//   },
//   {
//     action: 'Listen Call Record',
//     subject: 'HeroVisionX > Lead Management',
//   },
//   {
//     action: 'Download - Leads',
//     subject: 'HeroVisionX > Lead Management',
//   },
//   {
//     action: 'Download - Offline Conversion List',
//     subject: 'HeroVisionX > Lead Management',
//   },
//   {
//     action: 'Access',
//     subject: 'Spam Management',
//   },
//   {
//     action: 'Access',
//     subject: 'Lead Generation (TH)',
//   },
//   {
//     action: 'Access',
//     subject: 'Lead Generation (ID)',
//   },
//   {
//     action: 'Access',
//     subject: 'TikTok Lead Generation',
//   },
//   {
//     action: 'Access',
//     subject: 'Lead Service Support',
//   },
//   {
//     action: 'Access',
//     subject: 'Sales Performance (TH)',
//   },
//   {
//     action: 'Access',
//     subject: 'Sales Performance (ID)',
//   },
//   {
//     action: 'Access',
//     subject: 'Gross Profit By Account',
//   },
//   {
//     action: 'Access',
//     subject: 'Payment Calendar',
//   },
//   {
//     action: 'Access',
//     subject: 'Campus Point Tracking',
//   },
//   {
//     action: 'Access',
//     subject: 'Benchmark By Industry',
//   },
//   {
//     action: 'Access',
//     subject: 'Precautions Warning',
//   },
//   {
//     action: 'Access',
//     subject: 'Iconic - Campaign Performance',
//   },
//   {
//     action: 'Access',
//     subject: 'PMKT Bi-Weekly MOM',
//   },
//   {
//     action: 'Access',
//     subject: 'Missing ACI',
//   },
//   {
//     action: 'Access',
//     subject: 'Monthly PK Report',
//   },
//   {
//     action: 'Access',
//     subject: 'Domains',
//   },
//   {
//     action: 'Access',
//     subject: 'Products',
//   },
//   {
//     action: 'Edit',
//     subject: 'Products',
//   },
//   {
//     action: 'Access',
//     subject: 'Products Families',
//   },
//   {
//     action: 'Access',
//     subject: 'Clients',
//   },
//   {
//     action: 'Edit',
//     subject: 'Clients',
//   },
//   {
//     action: 'Access',
//     subject: 'Permissions',
//   },
//   {
//     action: 'Edit',
//     subject: 'Permissions',
//   },
//   {
//     action: 'Access',
//     subject: 'Roles',
//   },
//   {
//     action: 'Edit',
//     subject: 'Roles',
//   },
//   {
//     action: 'Access',
//     subject: 'Users',
//   },
//   {
//     action: 'Edit',
//     subject: 'Users',
//   },
//   {
//     action: 'Access',
//     subject: 'Countries',
//   },
//   {
//     action: 'Edit',
//     subject: 'Countries',
//   },
//   {
//     action: 'Access',
//     subject: 'KPI Units',
//   },
//   {
//     action: 'Edit',
//     subject: 'KPI Units',
//   },
//   {
//     action: 'Access',
//     subject: 'Languages',
//   },
//   {
//     action: 'Edit',
//     subject: 'Languages',
//   },
//   {
//     action: 'Access',
//     subject: 'Lead Sources',
//   },
//   {
//     action: 'Edit',
//     subject: 'Lead Sources',
//   },
//   {
//     action: 'Access',
//     subject: 'Metric Units',
//   },
//   {
//     action: 'Edit',
//     subject: 'Metric Units',
//   },
//   {
//     action: 'Access',
//     subject: 'Campus Point Limits',
//   },
//   {
//     action: 'Edit',
//     subject: 'Campus Point Limits',
//   },
//   {
//     action: 'Access',
//     subject: 'User Positions',
//   },
//   {
//     action: 'Edit',
//     subject: 'User Positions',
//   },
//   {
//     action: 'Access',
//     subject: 'Exchange Rates',
//   },
//   {
//     action: 'Edit',
//     subject: 'Exchange Rates',
//   },
// ]

export default {
  namespaced: true,
  state: {
    isAuthenticated: false,
    sanctumToken: null,
    loginData: {},
    permissions: [],
    loginTime: null,
  },

  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated
    },
    sanctumToken(state) {
      return state.sanctumToken
    },
    loginData(state) {
      return state.loginData
    },
    fullName(state) {
      const lastName = state.loginData.last_name ? state.loginData.last_name : ''

      return `${state.loginData.first_name} ${lastName}`
    },
    avatar(state) {
      return state.loginData.avatar
    },
    getPermissions(state) {
      // return mockPermissions
      return state.permissions
    },
    getUserType(state) {
      return state.loginData.user_type
    },
    getLoginTime(state) {
      return state.loginTime
    },
  },

  mutations: {
    doLogout(state) {
      state.isAuthenticated = false
      state.sanctumToken = null
      state.loginData = {}
      state.permissions = []
      state.loginTime = null
      localStorage.removeItem('sanctumToken')
    },
    doLogin(state, payload) {
      state.isAuthenticated = true
      state.sanctumToken = payload.sanctumToken
      state.loginData = payload.loginData
      state.loginTime = payload.loginTime
      localStorage.setItem('sanctumToken', payload.sanctumToken)
    },
    setPermissions(state, payload) {
      state.permissions = payload
    },
  },

  actions: {
    async convertPermissions(context) {
      // Convert observer to array
      const permissionData = JSON.parse(JSON.stringify(context.state.loginData.permissions))

      if (permissionData.length > 0) {
        const permissions = permissionData.map(item => ({ action: item.ability, subject: item.subject }))
        context.commit('setPermissions', permissions)
      }
    },
  },
}
