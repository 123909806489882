import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line import/no-cycle
import store from '@/store'

import heroai from '@/router/heroai/heroai'
import herovision from '@/router/herovision'
import reports from '@/router/reports'
import leadGeneration from '@/router/lead-generation'
import spamManagement from '@/router/spam-management'
import domains from '@/router/domains'
import sandbox from '@/router/sandbox'
import moment from 'moment'
import Constant from '@/utils/Constant'
import AxiosConfig from '@/services/AxiosConfigs'
import axios from '@/libs/axios'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...heroai,
    ...herovision,
    ...reports,
    ...leadGeneration,
    ...spamManagement,
    ...domains,
    ...sandbox,
    {
      path: '/tiktok/lead-generation/callback',
      name: 'tiktok-lead-generation-callback',
      component: () => import('@/views/tiktok/lead-generation/Callback.vue'),
      meta: {
        pageTitle: 'TikTok Lead Generation',
      },
      resource: 'Lead Management',
      action: 'Access',
    },
    {
      path: '/page-not-found',
      name: 'page-not-found',
      component: () => import('@/views/error/PageNotFound.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/error/NotAuthorized.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'page-not-found',
    },
  ],
})

const loginTimeRouteCheckList = [
  'campaigns-list',
  'accounts-list',
  'opportunities-list',
  'channel-performance',
  'spam-management-leads',
  'herovision-index',
]

const facebookReviewerRoutes = [
  'lead-generation-th',
  'lead-generation-id',
  'ads-campaign-syncing',
]

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters['heroAiAuthentications/isAuthenticated']
  const userType = store.getters['heroAiAuthentications/getUserType']
  const loginTime = store.getters['heroAiAuthentications/getLoginTime']
  const permissions = store.getters['heroAiAuthentications/getPermissions']
  const creatorId = store.getters['heroAiAuthentications/loginData']?.id
  const roles = store.getters['heroAiAuthentications/loginData']?.roles || []

  const isFacebookReviewer = roles.some(role => role.name === 'Facebook Reviewer') && roles.length === 1

  const { resource, action } = to.meta

  const getPermissionAccess = async () => {
    // Bypass
    if (userType === 'External' || isFacebookReviewer) {
      return true
    }

    if (resource === 'Campaigns') {
      const hasMyCampaignAccess = permissions.some(permission => permission.action === 'Access - My Campaign')
      const hasAllCampaignAccess = permissions.some(permission => permission.action === 'Access - All Campaign')

      const { id } = to.params

      if (id) {
        if (hasMyCampaignAccess && !hasAllCampaignAccess) {
          try {
            const token = store.getters['heroAiAuthentications/sanctumToken']
            const axiosConfig = AxiosConfig.getJsonWithToken(token)

            const { data } = await axios(Constant.apiURL.campaign.list, {
              method: 'post',
              headers: axiosConfig.headers,
              data: {
                creator_id: creatorId,
              },
            })

            if (data.status) {
              const campaigns = data.data.data
              return campaigns.some(campaign => campaign.campaign_id === id)
            }

            return false
          } catch (_) {
            return false
          }
        }
      }
      return hasMyCampaignAccess || hasAllCampaignAccess
    }

    return !resource
        || permissions.some(permission => permission.subject === resource && permission.action === action)
  }

  const hasPermissionAccess = await getPermissionAccess()

  if ((!loginTime && loginTimeRouteCheckList.includes(to.name))) {
    next({ name: 'heroai-logout' })
  } else {
    const now = moment(new Date())
    const diffHours = moment.duration(now.diff(moment(loginTime))).asHours()

    if (diffHours > 10 && loginTimeRouteCheckList.includes(to.name)) {
      next({ name: 'heroai-logout' })
    } else if (hasPermissionAccess) {
      // Facebook reviewer
      if (isFacebookReviewer && !facebookReviewerRoutes.includes(to.name) && to.name !== 'heroai-logout') {
        next({ name: 'lead-generation-th' })
      }

      // ป้องกันไม่ให้ Client เข้าถึงระบบ HeroAI ให้อยู่แค่ภายใน HeroVision
      if (userType === 'External') {
        if (
          to.name !== 'client-login'
              && to.name !== 'heroai-logout'
              && to.name !== 'herovision-index'
        ) {
          next({ name: 'herovision-index' })
        }
      }

      if (to.name === 'heroai-login' && isAuthenticated) {
        if (isFacebookReviewer) {
          next({ name: 'lead-generation-th' })
        } else {
          next({ name: 'campaigns-list' })
        }
      } else {
        next()
      }
    } else if (to.name !== 'not-authorized') {
      next({ name: 'not-authorized' })
    }
  }

  // console.log(from)
  // console.log(to)

  // // ป้องกันไม่ให้ Client เข้าถึงระบบ HeroAI ให้อยู่แค่ภายใน HeroVision
  // if (userType === 'External') {
  //   if (
  //
  //     to.name !== 'client-login'
  //     && to.name !== 'heroai-logout'
  //     && to.name !== 'herovision-index'
  //   ) {
  //     next({ name: 'herovision-index' })
  //   }
  // }
  //
  // if (to.name === 'heroai-login' && isAuthenticated) {
  //   next({ name: 'campaigns-list' })
  // } else {
  //   next()
  // }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
