import moment from 'moment-timezone'
import DropdownService from '@/services/DropdownService'

async function fetchData(context, page, lastPage) {
  if (lastPage >= page) {
    const url = `/roles?perPage=50&page=${page}&orderByColumn=name&orderByDirection=asc`
    const data = await DropdownService.loadData(url, 2)
    await context.commit('setData', data.data)

    await fetchData(context, page + 1, data.last_page)
  }
}

export default {
  namespaced: true,
  state: {
    loadedTime: null,
    doUpdate: false,
    data: [],
    optionsById: [],
    optionsByName: [],
    selectedRoleId: null,
  },

  getters: {
    getOptionsById(state) {
      return state.optionsById
    },

    getOptionsByName(state) {
      return state.optionsByName
    },

    // เอาไว้แสดงผลบนหัวตารางที่สองในหน้า Roles
    getRoleName(state) {
      if (state.selectedRoleId) {
        const filter = state.data.find(item => item.id === state.selectedRoleId)
        return `${filter.name} Role`
      }

      return null
    },
  },

  mutations: {
    clearData(state) {
      state.data = []
    },
    setLoadedTime(state, payload) {
      state.loadedTime = payload
    },
    setDoUpdate(state, payload) {
      state.doUpdate = payload
    },
    setData(state, payload) {
      state.data = [...state.data, ...payload]
    },
    setOptionsById(state, payload) {
      state.optionsById = payload
    },
    setOptionsByName(state, payload) {
      state.optionsByName = payload
    },
    setSelectedRoleId(state, payload) {
      state.selectedRoleId = payload
    },
  },

  actions: {
    async fetchOptions(context) {
      const { loadedTime, doUpdate } = context.state

      if (doUpdate || !loadedTime || moment().diff(loadedTime, 'minute') > process.env.VUE_APP_FETCH_ROLE) {
        context.commit('setDoUpdate', false)
        context.commit('setLoadedTime', moment().tz('Asia/Bangkok').format())
        context.commit('clearData')
        await fetchData(context, 1, 1)
      }

      const { data } = context.state
      const optionsById = data.map(item => ({ text: item.name, value: item.id }))
      const optionsByName = data.map(item => ({ text: item.name, value: item.name }))

      context.commit('setOptionsById', optionsById)
      context.commit('setOptionsByName', optionsByName)
    }, // fetchOptions
  }, // actions
}
