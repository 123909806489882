/* ------------------------------------------------------------------------------------------------------------------ */
/* --- This one used in page settings/roles ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------ */

import moment from 'moment-timezone'
import DropdownService from '@/services/DropdownService'

async function fetchData(context, page, lastPage) {
  if (lastPage >= page) {
    const url = `/permissions?perPage=50&page=${page}&orderByColumn=name&orderByDirection=asc`
    const data = await DropdownService.loadData(url, 2)
    await context.commit('setData', data.data)

    await fetchData(context, page + 1, data.last_page)
  }
}

export default {
  namespaced: true,
  state: {
    loadedTime: null,
    doUpdate: false,
    data: [],
    permissionIds: [],
    options: [],
  },

  getters: {
    getOptions(state) {
      return state.options
    },
  },

  mutations: {
    clearData(state) {
      state.data = []
    },
    setLoadedTime(state, payload) {
      state.loadedTime = payload
    },
    setDoUpdate(state, payload) {
      state.doUpdate = payload
    },
    setData(state, payload) {
      state.data = [...state.data, ...payload]
    },
    setPermissionIds(state, payload) {
      state.permissionIds = payload.map(permission => permission.id)
    },
    setOptions(state, payload) {
      state.options = payload
    },
  },

  actions: {
    async fetchOptions(context) {
      const { loadedTime, doUpdate, permissionIds } = context.state

      // รายการ Permission ทั้งหมดที่ได้มาจากฐานข้อมูล
      if (doUpdate || !loadedTime || moment().diff(loadedTime, 'minute') > process.env.VUE_APP_FETCH_PERMISSION) {
        context.commit('setDoUpdate', false)
        context.commit('setLoadedTime', moment().tz('Asia/Bangkok').format())
        context.commit('clearData')
        await fetchData(context, 1, 1)
      }

      // ทำการเปรียบเทียบกับ Permission Id ของ Role ที่เลือกอยู่
      const { data } = context.state
      data.forEach(item => {
        if (permissionIds.includes(item.id) || !item.is_active) {
          // eslint-disable-next-line no-param-reassign
          item.display = false
        } else {
          // eslint-disable-next-line no-param-reassign
          item.display = true
        }
      })

      // สร้างรายการ Object
      const options = []
      data.forEach(item => {
        if (item.display) {
          options.push({ text: item.name, value: item.name })
        }
      })

      context.commit('setOptions', options)
    }, // fetchOptions
  }, // actions
}
