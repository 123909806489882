import { DATE_FORMAT } from '@/utils/Format'
import moment from 'moment'

export default class CampaignOverview {
  constructor(item = {}) {
    return {
      name: item.campaign_name || '',
      campaign_id: item.campaign_id || '',
      alpha_campaign_id: item.alpha_campaign_id || '',
      campaign_status_name: item.campaign_status_name || '',
      campaign_status_id: item.campaign_status_id || '',
      brief_date: item.brief_date ? moment(item.brief_date).format(DATE_FORMAT) || '' : '',
      actual_campaign_start_date: item.campaign_start_date ? moment(item.campaign_start_date).format(DATE_FORMAT) || '' : '',
      actual_campaign_end_date: item.campaign_end_date ? moment(item.campaign_end_date).format(DATE_FORMAT) || '' : '',
      paused_date: item.paused_date ? moment(item.paused_date).format(DATE_FORMAT) || '' : '',
      unpaused_date: item.unpaused_date ? moment(item.unpaused_date).format(DATE_FORMAT) || '' : '',
      churn_date: item.churn_date ? moment(item.churn_date).format(DATE_FORMAT) || '' : '',
      heroai_campaign_id: item.heroai_campaign_id || '',
      google_account_id: item.google_account_id || [],
      facebook_account_id: item.facebook_account_id || [],
      tiktok_account_id: item.tiktok_account_id || [],
      buffer: item.buffer || '0',
      bonus: item.bonus_discount || '',
      ga_property_id: item.ga_property_id || '',
      ga_profile_id: item.ga_profile_id || '',
      client_website: item.client_website || '',
      duration: item.duration || '',
      business_objectives: item.business_objectives || '',
      business_objectives_name: item.business_objectives_name || '',
      client_at_risk_stage: item.client_at_risk_stage || 'None',
      reason_for_high_risk: item.reason_for_high_risk || '',
      demo_campaign: item.demo_campaign || false,
    }
  }
}
