import moment from 'moment-timezone'
import DropdownService from '@/services/DropdownService'

async function fetchData(context, page, lastPage) {
  if (lastPage >= page) {
    const url = `/metricUnits?perPage=50&page=${page}&orderByColumn=name&orderByDirection=asc`
    const data = await DropdownService.loadData(url, 2)
    await context.commit('setData', data.data)

    await fetchData(context, page + 1, data.last_page)
  }
}

export default {
  namespaced: true,
  state: {
    loadedTime: null,
    doUpdate: false,
    data: [],
    options: [],
  },

  getters: {
    getOptions(state) {
      return state.options
    },
  },

  mutations: {
    clearData(state) {
      state.data = []
    },
    setLoadedTime(state, payload) {
      state.loadedTime = payload
    },
    setDoUpdate(state, payload) {
      state.doUpdate = payload
    },
    setData(state, payload) {
      state.data = [...state.data, ...payload]
    },
    setOptions(state, payload) {
      state.options = payload
    },
  },

  actions: {
    async fetchOptions(context) {
      const { loadedTime, doUpdate } = context.state

      if (doUpdate || !loadedTime || moment().diff(loadedTime, 'minute') > process.env.VUE_APP_FETCH_METRIC_UNIT) {
        context.commit('setDoUpdate', false)
        context.commit('setLoadedTime', moment().tz('Asia/Bangkok').format())
        context.commit('clearData')
        await fetchData(context, 1, 1)
      }

      const { data } = context.state
      const options = data.map(item => ({ text: item.name, value: item.key }))

      context.commit('setOptions', options)
    }, // fetchOptions
  }, // actions
}
